import React from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import Header from "./Header";
import Timer from "./Timer";
import About from "./About";
import Preguntas from "./Preguntas";
import Final from "./Final";
import { Preloader, Placeholder } from "react-preloading-screen";
class Formulario extends React.Component {
  state = {
    mostrar: 0,
    termino: 0,
    timer: 0,
    loading: true,
  };
  mostrar = (mostrar) => {
    this.setState(() => ({ timer: mostrar }));
  };
  termino = (termino) => {
    if (termino === 2) {
      this.envioAuto();
    }
  };

  envioAuto = () => {
    //Información Personal
    const rut = document.getElementById("rut").value;
    const firstname = document.getElementById("firstname").value;
    const lastname = document.getElementById("lastname").value;
    const cargo = document.getElementById("cargo").value;
    const email = document.getElementById("email").value;
    const telefono = document.getElementById("telefono").value;
    const edad = document.getElementById("edad").value;
    const gender =
      document.querySelector("input[name=gender]:checked").value !== null
        ? document.querySelector("input[name=gender]:checked").value
        : "";

    //Preguntas
    let puntaje = 0;
    const p1 =
      document.querySelector("input[name=p1]:checked") === null
        ? ""
        : document.querySelector("input[name=p1]:checked").value;

    if (p1 === "4") {
      puntaje++;
    }

    //P2
    const p2 =
      document.querySelector("input[name=p2]:checked") === null
        ? ""
        : document.querySelector("input[name=p2]:checked").value;

    if (p2 === "2") {
      puntaje++;
      console.log("agrego p2");
    }
    //P3
    const p3 = document.getElementById("p3").value;
    if (p3 === "5") {
      puntaje++;
      console.log("agrego p3");
    }
    //P4
    const p4 =
      document.querySelector("input[name=p4]:checked") === null
        ? ""
        : document.querySelector("input[name=p4]:checked").value;
    if (p4 === "2") {
      puntaje++;
      console.log("agrego p4");
    }
    //P5
    const p5 =
      document.querySelector("input[name=p5]:checked") === null
        ? ""
        : document.querySelector("input[name=p5]:checked").value;
    if (p5 === "3") {
      puntaje++;
      console.log("agrego p5");
    }
    //P6
    const p6 =
      document.querySelector("input[name=p6]:checked") === null
        ? ""
        : document.querySelector("input[name=p6]:checked").value;

    if (p6 === "1") {
      puntaje++;
      console.log("agrego p6");
    }
    //P7
    const p7 =
      document.querySelector("input[name=p7]:checked") === null
        ? ""
        : document.querySelector("input[name=p7]:checked").value;
    if (p7 === "1") {
      puntaje++;
      console.log("agrego p7");
    }
    //P8
    const p8 =
      document.querySelector("input[name=p8]:checked") === null
        ? ""
        : document.querySelector("input[name=p8]:checked").value;
    if (p8 === "2") {
      puntaje++;
      console.log("agrego p8");
    }
    //P9
    const p9 =
      document.querySelector("input[name=p9]:checked") === null
        ? ""
        : document.querySelector("input[name=p9]:checked").value;
    if (p9 === "3") {
      puntaje++;
      console.log("agrego p9");
    }
    //P10
    const p10 =
      document.querySelector("input[name=p10]:checked") === null
        ? ""
        : document.querySelector("input[name=p10]:checked").value;
    if (p10 === "4") {
      puntaje++;
      console.log("agrego p10");
    }
    //P11
    const p11 =
      document.querySelector("input[name=p11]:checked") === null
        ? ""
        : document.querySelector("input[name=p11]:checked").value;
    if (p11 === "4") {
      puntaje++;
      console.log("agrego p11");
    }
    //P12
    const p12 =
      document.querySelector("input[name=p12]:checked") === null
        ? ""
        : document.querySelector("input[name=p12]:checked").value;
    if (p12 === "3") {
      puntaje++;
      console.log("agrego p12");
    }
    //P13
    const p13 = document.getElementById("p13").value;
    if (p13 === "2") {
      puntaje++;
      console.log("agrego p13");
    }
    //P14
    const p14 = document.getElementById("p14").value;
    if (p14 === "8") {
      puntaje++;
      console.log("agrego p14");
    }
    //P15
    const p15 =
      document.querySelector("input[name=p15]:checked") === null
        ? ""
        : document.querySelector("input[name=p15]:checked").value;
    if (p15 === "3") {
      puntaje++;
      console.log("agrego p15");
    }
    //P16
    const p16 =
      document.querySelector("input[name=p16]:checked") === null
        ? ""
        : document.querySelector("input[name=p16]:checked").value;
    if (p16 === "1") {
      puntaje++;
      console.log("agrego p16");
    }
    //P17
    const p17 = document.getElementById("p17").value;
    if (p17 === "8") {
      puntaje++;
      console.log("agrego p17");
    }
    //P18
    const p18 =
      document.querySelector("input[name=p18]:checked") === null
        ? ""
        : document.querySelector("input[name=p18]:checked").value;
    if (p18 === "3") {
      puntaje++;
      console.log("agrego p18");
    }
    //P19
    const p19_1 =
      document.querySelector("input[id=p19-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-1]:checked");
    const p19_2 =
      document.querySelector("input[id=p19-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-2]:checked");
    const p19_3 =
      document.querySelector("input[id=p19-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-3]:checked");
    const p19_4 =
      document.querySelector("input[id=p19-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-4]:checked");
    const p19_5 =
      document.querySelector("input[id=p19-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-5]:checked");

    if (
      p19_1.checked &&
      !p19_2.checked &&
      p19_3.checked &&
      !p19_4.checked &&
      !p19_5.checked
    ) {
      puntaje++;
      console.log("agrego p19");
    }
    //P20
    const p20 =
      document.querySelector("input[name=p20]:checked") === null
        ? ""
        : document.querySelector("input[name=p20]:checked").value;
    if (p20 === "5") {
      puntaje++;
      console.log("agrego p20");
    }
    //P21
    const p21 =
      document.querySelector("input[name=p21]:checked") === null
        ? ""
        : document.querySelector("input[name=p21]:checked").value;
    if (p21 === "5") {
      puntaje++;
      console.log("agrego p21");
    }
    //P22
    const p22 =
      document.querySelector("input[name=p22]:checked") === null
        ? ""
        : document.querySelector("input[name=p22]:checked").value;
    if (p22 === "2") {
      puntaje++;
      console.log("agrego p22");
    }
    //P23
    const p23_1 =
      document.querySelector("input[id=p23-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-1]:checked");
    const p23_2 =
      document.querySelector("input[id=p23-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-2]:checked");
    const p23_3 =
      document.querySelector("input[id=p23-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-3]:checked");
    const p23_4 =
      document.querySelector("input[id=p23-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-4]:checked");
    const p23_5 =
      document.querySelector("input[id=p23-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-5]:checked");

    if (
      !p23_1.checked &&
      p23_2.checked &&
      !p23_3.checked &&
      !p23_4.checked &&
      p23_5.checked
    ) {
      puntaje++;
      console.log("agrego p23");
    }
    //P24
    const p24 = document.getElementById("p24").value;

    if (p24 === "8") {
      puntaje++;
      console.log("agrego p24");
    }
    //P25
    const p25 =
      document.querySelector("input[name=p25]:checked") === null
        ? ""
        : document.querySelector("input[name=p25]:checked").value;
    if (p25 === "3") {
      puntaje++;
      console.log("agrego p25");
    }
    //P26
    const p26 =
      document.querySelector("input[name=p26]:checked") === null
        ? ""
        : document.querySelector("input[name=p26]:checked").value;
    if (p26 === "1") {
      puntaje++;
      console.log("agrego p26");
    }
    //P27
    const p27 = document.getElementById("p27").value;
    if (p27 === "26") {
      puntaje++;
      console.log("agrego p27");
    }
    //P28
    const p28 =
      document.querySelector("input[name=p28]:checked") === null
        ? ""
        : document.querySelector("input[name=p28]:checked").value;
    if (p28 === "3") {
      puntaje++;
      console.log("agrego p28");
    }
    //P29
    const p29 =
      document.querySelector("input[name=p29]:checked") === null
        ? ""
        : document.querySelector("input[name=p29]:checked").value;
    if (p29 === "3") {
      puntaje++;
      console.log("agrego p29");
    }
    //P30
    const p30 =
      document.querySelector("input[name=p30]:checked") === null
        ? ""
        : document.querySelector("input[name=p30]:checked").value;
    if (p30 === "3") {
      puntaje++;
      console.log("agrego p30");
    }
    //P31
    const p31_1 =
      document.querySelector("input[id=p31-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-1]:checked");
    const p31_2 =
      document.querySelector("input[id=p31-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-2]:checked");
    const p31_3 =
      document.querySelector("input[id=p31-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-3]:checked");
    const p31_4 =
      document.querySelector("input[id=p31-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-4]:checked");
    const p31_5 =
      document.querySelector("input[id=p31-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-5]:checked");

    if (
      p31_1.checked &&
      !p31_2.checked &&
      p31_3.checked &&
      p31_4.checked &&
      p31_5.checked
    ) {
      puntaje++;
      console.log("agrego p31");
    }
    //P32
    const p32 =
      document.querySelector("input[name=p32]:checked") === null
        ? ""
        : document.querySelector("input[name=p32]:checked").value;
    if (p32 === "1") {
      puntaje++;
      console.log("agrego p32");
    }
    //P33
    const p33 =
      document.querySelector("input[name=p33]:checked") === null
        ? ""
        : document.querySelector("input[name=p33]:checked").value;
    if (p33 === "6") {
      puntaje++;
      console.log("agrego p33");
    }
    //P34
    const p34 =
      document.querySelector("input[name=p34]:checked") === null
        ? ""
        : document.querySelector("input[name=p34]:checked").value;
    if (p34 === "1") {
      puntaje++;
      console.log("agrego p34");
    }
    //P35
    const p35 = document.getElementById("p35").value;
    if (p35 === "25") {
      puntaje++;
      console.log("agrego p35");
    }
    //P36
    const p36 =
      document.querySelector("input[name=p36]:checked") === null
        ? ""
        : document.querySelector("input[name=p36]:checked").value;
    if (p36 === "4") {
      puntaje++;
      console.log("agrego p36");
    }
    //P37
    const p37_1 =
      document.querySelector("input[id=p37-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-1]:checked");
    const p37_2 =
      document.querySelector("input[id=p37-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-2]:checked");
    const p37_3 =
      document.querySelector("input[id=p37-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-3]:checked");
    const p37_4 =
      document.querySelector("input[id=p37-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-4]:checked");
    const p37_5 =
      document.querySelector("input[id=p37-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-5]:checked");

    if (
      !p37_1.checked &&
      p37_2.checked &&
      !p37_3.checked &&
      p37_4.checked &&
      !p37_5.checked
    ) {
      puntaje++;
      console.log("agrego p37");
    }
    //P38
    const p38 =
      document.querySelector("input[name=p38]:checked") === null
        ? ""
        : document.querySelector("input[name=p38]:checked").value;
    if (p38 === "2") {
      puntaje++;
      console.log("agrego p38");
    }
    //P39
    const p39 = document.getElementById("p39").value;
    if (p39 === "1") {
      puntaje++;
      console.log("agrego p39");
    }
    //P40
    const p40 =
      document.querySelector("input[name=p40]:checked") === null
        ? ""
        : document.querySelector("input[name=p40]:checked").value;
    if (p40 === "5") {
      puntaje++;
      console.log("agrego p40");
    }
    //P41
    const p41 = document.getElementById("p41").value;
    if (p41 === "45") {
      puntaje++;
      console.log("agrego p41");
    }
    //P42
    const p42 =
      document.querySelector("input[name=p42]:checked") === null
        ? ""
        : document.querySelector("input[name=p42]:checked").value;
    if (p42 === "3") {
      puntaje++;
      console.log("agrego p42");
    }
    //P43
    const p43 =
      document.querySelector("input[name=p43]:checked") === null
        ? ""
        : document.querySelector("input[name=p43]:checked").value;
    if (p43 === "6") {
      puntaje++;
      console.log("agrego p43");
    }
    //P44
    const p44 = document.getElementById("p44").value;
    if (p44 === "8,12") {
      puntaje++;
      console.log("agrego p44");
    }
    //P45
    const p45 = document.getElementById("p45").value;
    if (p45 === "950") {
      puntaje++;
      console.log("agrego p45");
    }
    //P46
    const p46 = document.getElementById("p46").value;
    if (p46 === "0.0625") {
      puntaje++;
      console.log("agrego p46");
    }
    //P47
    const p47_1 =
      document.querySelector("input[id=p47-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-1]:checked");
    const p47_2 =
      document.querySelector("input[id=p47-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-2]:checked");
    const p47_3 =
      document.querySelector("input[id=p47-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-3]:checked");
    const p47_4 =
      document.querySelector("input[id=p47-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-4]:checked");
    const p47_5 =
      document.querySelector("input[id=p47-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-5]:checked");

    if (
      p47_1.checked &&
      !p47_2.checked &&
      !p47_3.checked &&
      p47_4.checked &&
      !p47_5.checked
    ) {
      puntaje++;
      console.log("agrego p47");
    }
    //P48
    const p48 =
      document.querySelector("input[name=p48]:checked") === null
        ? ""
        : document.querySelector("input[name=p48]:checked").value;
    if (p48 === "3") {
      puntaje++;
      console.log("agrego p48");
    }
    //P49
    const p49 = document.getElementById("p49").value;
    if (p49 === "12") {
      puntaje++;
      console.log("agrego p49");
    }
    //P50
    const p50 =
      document.querySelector("input[name=p50]:checked") === null
        ? ""
        : document.querySelector("input[name=p50]:checked").value;
    if (p50 === "2") {
      puntaje++;
      console.log("agrego p50");
    }
    axios({
      method: "post",
      url: "https://wonderlictest.herokuapp.com/api/wonderlic/addresult",
      data: {
        rut: rut,
        firstname: firstname,
        lastname: lastname,
        cargo: cargo,
        email: email,
        telefono: telefono,
        edad: edad,
        gender: gender,
        puntaje: puntaje,
      },
    })
      .then((response) => {
        this.setState(() => ({ mostrar: 2 }));
      })
      .catch((error) => {
        alert("Error :c");
      });
  };

  enviar = (e) => {
    //Información Personal
    const rut = e.target.elements.rut.value;
    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const cargo = e.target.elements.cargo.value;
    const email = e.target.elements.email.value;
    const telefono = e.target.elements.telefono.value;
    const edad = e.target.elements.edad.value;
    const gender = e.target.elements.gender.value;

    //Preguntas
    let puntaje = 0;
    //P1
    const p1 = e.target.elements.p1.value;
    if (p1 === "4") {
      puntaje++;
    }
    //P2
    const p2 = e.target.elements.p2.value;
    if (p2 === "2") {
      puntaje++;
    }
    //P3
    const p3 = e.target.elements.p3.value;
    if (p3 === "5") {
      puntaje++;
    }
    //P4
    const p4 = e.target.elements.p4.value;
    if (p4 === "2") {
      puntaje++;
    }
    //P5
    const p5 = e.target.elements.p5.value;
    if (p5 === "3") {
      puntaje++;
    }
    //P6
    const p6 = e.target.elements.p6.value;
    if (p6 === "1") {
      puntaje++;
    }
    //P7
    const p7 = e.target.elements.p7.value;
    if (p7 === "1") {
      puntaje++;
    }
    //P8
    const p8 = e.target.elements.p8.value;
    if (p8 === "2") {
      puntaje++;
    }
    //P9
    const p9 = e.target.elements.p9.value;
    if (p9 === "3") {
      puntaje++;
    }
    //P10
    const p10 = e.target.elements.p10.value;
    if (p10 === "4") {
      puntaje++;
      console.log("agrego p10");
    }
    //P11
    const p11 = e.target.elements.p11.value;
    if (p11 === "4") {
      puntaje++;
      console.log("agrego p11");
    }
    //P12
    const p12 = e.target.elements.p12.value;
    if (p12 === "3") {
      puntaje++;
      console.log("agrego p12");
    }
    //P13
    const p13 = e.target.elements.p13.value;
    if (p13 === "2") {
      puntaje++;
      console.log("agrego p13");
    }
    //P14
    const p14 = e.target.elements.p14.value;
    if (p14 === "8") {
      puntaje++;
      console.log("agrego p14");
    }
    //P15
    const p15 = e.target.elements.p15.value;
    if (p15 === "3") {
      puntaje++;
      console.log("agrego p15");
    }
    //P16
    const p16 = e.target.elements.p16.value;
    if (p16 === "1") {
      puntaje++;
      console.log("agrego p16");
    }
    //P17
    const p17 = e.target.elements.p17.value;
    if (p17 === "8") {
      puntaje++;
      console.log("agrego p17");
    }
    //P18
    const p18 = e.target.elements.p18.value;
    if (p18 === "3") {
      puntaje++;
      console.log("agrego p18");
    }
    //P19
    const p19_1 =
      document.querySelector("input[id=p19-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-1]:checked");
    const p19_2 =
      document.querySelector("input[id=p19-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-2]:checked");
    const p19_3 =
      document.querySelector("input[id=p19-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-3]:checked");
    const p19_4 =
      document.querySelector("input[id=p19-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-4]:checked");
    const p19_5 =
      document.querySelector("input[id=p19-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p19-5]:checked");

    if (
      p19_1.checked &&
      !p19_2.checked &&
      p19_3.checked &&
      !p19_4.checked &&
      !p19_5.checked
    ) {
      puntaje++;
      console.log("agrego p19");
    }
    //P20
    const p20 = e.target.elements.p20.value;
    if (p20 === "5") {
      puntaje++;
      console.log("agrego p20");
    }
    //P21
    const p21 = e.target.elements.p21.value;
    if (p21 === "5") {
      puntaje++;
      console.log("agrego p21");
    }
    //P22
    const p22 = e.target.elements.p22.value;
    if (p22 === "2") {
      puntaje++;
      console.log("agrego p22");
    }
    //P23
    const p23_1 =
      document.querySelector("input[id=p23-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-1]:checked");
    const p23_2 =
      document.querySelector("input[id=p23-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-2]:checked");
    const p23_3 =
      document.querySelector("input[id=p23-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-3]:checked");
    const p23_4 =
      document.querySelector("input[id=p23-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-4]:checked");
    const p23_5 =
      document.querySelector("input[id=p23-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p23-5]:checked");

    if (
      !p23_1.checked &&
      p23_2.checked &&
      !p23_3.checked &&
      !p23_4.checked &&
      p23_5.checked
    ) {
      puntaje++;
      console.log("agrego p23");
    }
    //P24
    const p24 = e.target.elements.p24.value;
    if (p24 === "8") {
      puntaje++;
      console.log("agrego p24");
    }
    //P25
    const p25 = e.target.elements.p25.value;
    if (p25 === "3") {
      puntaje++;
      console.log("agrego p25");
    }
    //P26
    const p26 = e.target.elements.p26.value;
    if (p26 === "1") {
      puntaje++;
      console.log("agrego p26");
    }
    //P27
    const p27 = e.target.elements.p27.value;
    if (p27 === "26") {
      puntaje++;
      console.log("agrego p27");
    }
    //P28
    const p28 = e.target.elements.p28.value;
    if (p28 === "3") {
      puntaje++;
      console.log("agrego p28");
    }
    //P29
    const p29 = e.target.elements.p29.value;
    if (p29 === "3") {
      puntaje++;
      console.log("agrego p29");
    }
    //P30
    const p30 = e.target.elements.p30.value;
    if (p30 === "3") {
      puntaje++;
      console.log("agrego p30");
    }
    //P31
    const p31_1 =
      document.querySelector("input[id=p31-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-1]:checked");
    const p31_2 =
      document.querySelector("input[id=p31-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-2]:checked");
    const p31_3 =
      document.querySelector("input[id=p31-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-3]:checked");
    const p31_4 =
      document.querySelector("input[id=p31-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-4]:checked");
    const p31_5 =
      document.querySelector("input[id=p31-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p31-5]:checked");

    if (
      p31_1.checked &&
      !p31_2.checked &&
      p31_3.checked &&
      p31_4.checked &&
      p31_5.checked
    ) {
      puntaje++;
      console.log("agrego p31");
    }
    //P32
    const p32 = e.target.elements.p32.value;
    if (p32 === "1") {
      puntaje++;
      console.log("agrego p32");
    }
    //P33
    const p33 = e.target.elements.p33.value;
    if (p33 === "6") {
      puntaje++;
      console.log("agrego p33");
    }
    //P34
    const p34 = e.target.elements.p34.value;
    if (p34 === "1") {
      puntaje++;
      console.log("agrego p34");
    }
    //P35
    const p35 = e.target.elements.p35.value;
    if (p35 === "25") {
      puntaje++;
      console.log("agrego p35");
    }
    //P36
    const p36 = e.target.elements.p36.value;
    if (p36 === "4") {
      puntaje++;
      console.log("agrego p36");
    }
    //P37
    const p37_1 =
      document.querySelector("input[id=p37-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-1]:checked");
    const p37_2 =
      document.querySelector("input[id=p37-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-2]:checked");
    const p37_3 =
      document.querySelector("input[id=p37-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-3]:checked");
    const p37_4 =
      document.querySelector("input[id=p37-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-4]:checked");
    const p37_5 =
      document.querySelector("input[id=p37-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p37-5]:checked");

    if (
      !p37_1.checked &&
      p37_2.checked &&
      !p37_3.checked &&
      !p37_4.checked &&
      p37_5.checked
    ) {
      puntaje++;
      console.log("agrego p37");
    }
    //P38
    const p38 = e.target.elements.p38.value;
    if (p38 === "2") {
      puntaje++;
      console.log("agrego p38");
    }
    //P39
    const p39 = e.target.elements.p39.value;
    if (p39 === "1") {
      puntaje++;
      console.log("agrego p39");
    }
    //P40
    const p40 = e.target.elements.p40.value;
    if (p40 === "5") {
      puntaje++;
      console.log("agrego p40");
    }
    //P41
    const p41 = e.target.elements.p41.value;
    if (p41 === "45") {
      puntaje++;
      console.log("agrego p41");
    }
    //P42
    const p42 = e.target.elements.p42.value;
    if (p42 === "3") {
      puntaje++;
      console.log("agrego p42");
    }
    //P43
    const p43 = e.target.elements.p43.value;
    if (p43 === "6") {
      puntaje++;
      console.log("agrego p43");
    }
    //P44
    const p44 = e.target.elements.p44.value;
    if (p44 === "8,12") {
      puntaje++;
      console.log("agrego p44");
    }
    //P45
    const p45 = e.target.elements.p45.value;
    if (p45 === "950") {
      puntaje++;
      console.log("agrego p45");
    }
    //P46
    const p46 = e.target.elements.p46.value;
    if (p46 === "0.0625") {
      puntaje++;
      console.log("agrego p46");
    }
    //P47
    const p47_1 =
      document.querySelector("input[id=p47-1]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-1]:checked");
    const p47_2 =
      document.querySelector("input[id=p47-2]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-2]:checked");
    const p47_3 =
      document.querySelector("input[id=p47-3]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-3]:checked");
    const p47_4 =
      document.querySelector("input[id=p47-4]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-4]:checked");
    const p47_5 =
      document.querySelector("input[id=p47-5]:checked") === null
        ? ""
        : document.querySelector("input[id=p47-5]:checked");

    if (
      p47_1.checked &&
      !p47_2.checked &&
      !p47_3.checked &&
      p47_4.checked &&
      !p47_5.checked
    ) {
      puntaje++;
      console.log("agrego p47");
    }
    //P48
    const p48 = e.target.elements.p48.value;
    if (p48 === "3") {
      puntaje++;
      console.log("agrego p48");
    }
    //P49
    const p49 = e.target.elements.p49.value;
    if (p49 === "12") {
      puntaje++;
      console.log("agrego p49");
    }
    //P50
    const p50 = e.target.elements.p50.value;
    if (p50 === "2") {
      puntaje++;
      console.log("agrego p50");
    }

    axios({
      method: "post",
      url: "https://wonderlictest.herokuapp.com/api/wonderlic/addresult",
      data: {
        rut: rut,
        firstname: firstname,
        lastname: lastname,
        cargo: cargo,
        email: email,
        telefono: telefono,
        edad: edad,
        gender: gender,
        puntaje: puntaje,
      },
    })
      .then((response) => {
        this.setState(() => ({ mostrar: 2 }));
        this.setState(() => ({ timer: 2 }));
      })
      .catch((error) => {
        alert("Error :c");
      });
  };

  render() {
    return (
      <Preloader fadeDuration={1500}>
        <div>
          <Header />
          {this.state.timer === 1 && <Timer termino={this.termino} />}
          {this.state.mostrar !== 2 && (
            <Preguntas mostrar={this.mostrar} enviar={this.enviar} />
          )}
          {this.state.mostrar === 2 && <Final />}
        </div>

        <Placeholder>
          <ReactLoading
            type={"bubbles"}
            color={"#222"}
            height={"50px"}
            width={"50px"}
          />
        </Placeholder>
      </Preloader>
    );
  }
}

export default Formulario;
