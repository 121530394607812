import React from "react";

class Timer extends React.Component {
state = {
  timer:"12:00",
  second:720
};

interval = setInterval(() => {
  
  this.setState((prevState) => ({second: prevState.second - 1 }));
  let contador = this.state.second;
  if(contador === -1){
    this.termino();
    return;
  }
  let minutes = Math.floor(contador/ 60)<10?"0"+Math.floor(contador/ 60):""+Math.floor(contador/ 60);
  let seconds = (contador - minutes*60)<10?"0"+(contador - minutes*60):""+(contador - minutes*60);
  this.setState(() => ({timer:minutes+":"+seconds}));
  
  
 
},1000);

 termino = () => {
  alert("Se acabo el tiempo");
  clearInterval(this.interval);
  this.props.termino(2);

 }

  render() {
    return (
      <div class="timer">
           <h1>Tiempo: {this.state.timer}</h1>
      </div>
    );
  }
}

export default Timer;
