import React from 'react';

class Header extends React.Component {
  render(){
    return (
      <div>
        <nav class="navbar navbar-dark header">
        <a class="navbar-brand text-logo" href="#">
            <img src="img/img-03.png"  class="d-inline-block align-top logo" alt=""/>
        </a>
        </nav>
      </div>
    );
  }
}

export default Header;
