import React from "react";

class About extends React.Component {
  render() {
    return (
      <div className="container-fluid about-container">
        <div className="about">
          <div className="row">
            <div className="col-6  about-container">
              <img className="img-fluid" src="img/about-01.jpg" />
            </div>
            <div className="col-6 d-flex align-items-center about-container">
              <div className="row d-flex justify-content-center">
                <h1 className="text-center about-text ">
                  <i class="fas fa-check" /> Acerca de este test...
                </h1>
                <p className="text-center font-weight-bold">
                  Este cuenta con 50 preguntas, <br/>
                  que deben ser respondidas en 12 minutos. <br/>
                  Esto para evaluar su capacidad de resolver problemas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
