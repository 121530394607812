import React from "react";
import axios from "axios";
const { validate, clean, format } = require('rut.js');


class Preguntas extends React.Component {
  state = {
    count: 0,
    check: 0,
    id: "",
    valru: 0
  };

  forward = () => {
  
    if(this.state.count!== 1){
      this.setState(prevState => ({ count: prevState.count + 1 }));
    } else if(document.querySelectorAll(".form-control.required.error").length === 0){
      this.setState(prevState => ({ count: prevState.count + 1 }));
    }

    if (this.state.count === 7) {
      this.props.mostrar(1);
    }

    if (this.state.count === 6) {
      document.getElementById("sig").innerHTML = "Iniciar test";
    } else {
      document.getElementById("sig").innerHTML = "Siguiente";
    }

    if (this.state.count === 7) {
      document.getElementById("ante").style.display = "none";
    } else {
      document.getElementById("ante").style.display = "initial";
    }
  };
  backward = () => {
    if (this.state.count === 8) {
      document.getElementById("sig").innerHTML = "Iniciar test";
    } else {
      document.getElementById("sig").innerHTML = "Siguiente";
    }

    if (this.state.count === 9) {
      document.getElementById("ante").style.display = "none";
    } else {
      document.getElementById("ante").style.display = "initial";
    }
    this.setState(prevState => ({ count: prevState.count - 1 }));
  };

  enviarForm = e => {
    e.preventDefault();
    this.props.enviar(e);
  };
  validateRut = e => {
    e.preventDefault();

    var rut  =  document.getElementById("rut").value;
    rut = clean(rut);
    rut = format(rut);

    document.getElementById("rut").value = rut;

    if(!validate(rut) || rut.length<11){
      document.getElementById("rut").style.borderBottom = "2px solid red";
      document.getElementById("rut").focus();
      this.setState(() => ({ valru: 0 }));
    } else {
      document.getElementById("rut").style.borderBottom = "2px solid #E8EBEE";
      this.setState(() => ({ valru: 1}));
    }
     
  };

  rutFocus = e => {
    e.preventDefault();
      if(this.state.valru === 1){
        document.getElementById("rut").style.borderBottom = "2px solid #999";
      }
  }

  rutBlur = e => {
    e.preventDefault();
      if(this.state.valru === 1){
        document.getElementById("rut").style.borderBottom = "2px solid #E8EBEE";
      }
  }



  render() {
    return (
      <div id="form_container">
        <div class="row">
          <div class="col-lg-12">
            <div id="wizard_container">
              <div id="top-wizard">
                <div id="progressbar" />
              </div>
              <form name="" id="formito" onSubmit={this.enviarForm}>
                <input id="website" name="website" type="text" value="" />
                <div id="middle-wizard">
                  <div class="step">
                    <h3 class="main_question">Acerca del Test</h3>
                    <p>
                      El siguiente test está hecho para conocer la capacidad que
                      tienen las personas para resolver problemas complejos, es
                      comúnmente llamado un test de inteligencia. Contiene en su
                      interior múltiples preguntas de diferentes tipos que debes
                      resolver sin ayuda de calculadoras u otras aplicaciones
                      que sirvan de apoyo.
                      <img
                        className="rounded mx-auto d-block"
                        src="img/img-02.png"
                        width="280px"
                      />
                    </p>
                  </div>
                  <div class="step">
                    <h3 class="main_question">Información Personal</h3>
                    <h4>Antes que todo, te pedimos que llenes lo siguiente:</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group form2">
                          <input
                            id="rut"
                            type="text"
                            name="rut"
                            class="form-control required"
                            placeholder="Rut"
                            onKeyUp={this.validateRut}
                            onFocus={this.rutFocus}
                            onBlur={this.rutBlur}
                            maxlength="12"
                            
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group form2">
                          <input
                            id="firstname"
                            type="text"
                            name="firstname"
                            class="form-control required"
                            placeholder="Primer Nombre"
                            
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group form2">
                          <input
                            id="lastname"
                            type="text"
                            name="lastname"
                            class="form-control required"
                            placeholder="Apellido"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group form2">
                          <input
                            id="cargo"
                            type="text"
                            name="cargo"
                            class="form-control"
                            placeholder="Cargo al que postula"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group form2">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            class="form-control required"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group form2">
                          <input
                            id="telefono"
                            type="number"
                            name="telefono"
                            class="form-control"
                            placeholder="Teléfono"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group form2">
                          <input
                            id="edad"
                            type="number"
                            name="edad"
                            class="form-control number"
                            placeholder="Edad"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group radio_input">
                          <label>
                            <input
                              type="radio"
                              value="Hombre"
                              name="gender"
                              class="icheck required"
                            />
                            Hombre
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="Mujer"
                              name="gender"
                              class="icheck required"
                            />
                            Mujer
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="Otro"
                              name="gender"
                              class="icheck required"
                            />
                            Otro
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">Antes de comenzar...</h3>
                    <p>
                      El test tiene 50 preguntas y tendrás 12 minutos
                      cronometrados para intentar completarlas. Es muy común y
                      probable que no alcances a contestarlas todas, no te
                      preocupes por ello. Te recomendamos que contestes las
                      preguntas en el orden que van apareciendo, si encuentras
                      alguna muy dificultosa o si sientes que te estás demorando
                      mucho en alguna, simplemente salta a la siguiente. Es
                      importante que seas eficiente para aprovechar al máximo el
                      tiempo.
                      <img
                        className="rounded mx-auto d-block"
                        src="img/img-04.png"
                        width="240px"
                      />
                    </p>
                  </div>
                  <div class="step">
                    <h3 class="main_question">Preguntas de práctica</h3>
                    <p>
                      A continuación te invitamos a hacer algunas preguntas de
                      ejemplo para que te ambientes a lo que te podrías
                      encontrar dentro de la prueba. Para tu tranquilidad, estas
                      preguntas no afectarán tu puntaje ni tiempo. Una vez que
                      termines las preguntas de muestra debes estar preparado ya
                      que empezará la prueba inmediatamente.
                      <img
                        className="rounded mx-auto d-block"
                        src="img/img-05.jpg"
                        width="240px"
                      />
                    </p>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>1/3</strong>Preguntas de práctica:
                    </h3>
                    <h3>Alto es lo opuesto de:</h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>A .- Delgado</label>
                          <label>B .- Fuerte</label>
                          <label>C .- Bajo</label>
                          <label>D .- Feo</label>
                        </div>
                      </div>
                    </div>
                    <h4>RESPUESTA:</h4>
                    <h4 class="main_question">
                      En este caso, la respuesta correcta es la “C” (Bajo)
                    </h4>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>2/3</strong>Preguntas de práctica:
                    </h3>
                    <h3>
                      Un Kilo de manzanas se vende a $200 pesos ¿Cuántos pesos
                      costarían tres kilos de manzanas?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group res2">
                          <input
                            type="number"
                            name="p4"
                            class="form-control"
                            placeholder="Respuesta"
                            value="600"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                    <h4>RESPUESTA:</h4>
                    <h4 class="main_question">
                      En este caso, la respuesta correcta son $600 pesos. Cuando
                      te toque responder directamente la respuesta, sólo debes
                      colocar el número, es decir, en este caso sólo deberás
                      colocar: “600”.
                    </h4>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>3/3</strong>Preguntas de práctica:
                    </h3>
                    <h3>
                      ¿Cuál es la relación entre el significado de las palabras
                      “Maní y “Mamá”?
                    </h3>
                    <div class="row">
                      <div class="col-md-8">
                        <div class="form-group radio_input res">
                          <label>A .- Los significados son similares</label>
                          <label>B .- Los significados son opuestos</label>
                          <label>C .- No tienen ninguna relación</label>
                        </div>
                      </div>
                    </div>
                    <h3>RESPUESTA:</h3>
                    <h4 class="main_question">
                      En este caso, la respuesta correcta es la “C” (No tienen
                      ninguna relación).
                    </h4>
                  </div>
                  <div class="step">
                    <h3 class="main_question">Inicio de Test</h3>
                    <p>
                      A continuación comenzará la prueba definitiva, al momento
                      de hacer click en “Siguiente” empieza tu tiempo.
                      <img
                        className="rounded mx-auto d-block"
                        src="img/img-06.png"
                        width="240px"
                      />
                    </p>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>1/50</strong>De este grupo de palabras ¿Cuál no
                      tiene relación con las otras?
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p1"
                              class="icheck"
                            />
                            Cobre
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p1"
                              class="icheck"
                            />
                            Aluminio
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p1"
                              class="icheck"
                            />
                            Oro
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p1"
                              class="icheck"
                            />
                            Madera
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p1"
                              class="icheck"
                            />
                            Bronce
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>2/50</strong>Vaca es a Ternero, como Oso es a?
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p2"
                              class="icheck"
                            />
                            Pollo
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p2"
                              class="icheck"
                            />
                            Osezno
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p2"
                              class="icheck"
                            />
                            Cervato
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p2"
                              class="icheck"
                            />
                            Trucha
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p2"
                              class="icheck"
                            />
                            Cachorro
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>3/50</strong>En 20 días una persona ahorró un peso
                      ¿Cuál es porcentaje promedio de ahorro por día?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="number"
                            name="p3"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>4/50</strong>De este grupo de palabras ¿Cuál no se
                      relaciona con las otras?
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p4"
                              class="icheck"
                            />
                            Enero
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p4"
                              class="icheck"
                            />
                            Lunes
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p4"
                              class="icheck"
                            />
                            Marzo
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p4"
                              class="icheck"
                            />
                            Abril
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p4"
                              class="icheck"
                            />
                            Agosto
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>5/50</strong>¿Cuál es la relación entre el
                      significado de las palabras Hipócrita e Histérica?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p5"
                              class="icheck"
                            />
                            Son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p5"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p5"
                              class="icheck"
                            />
                            No tienen relación
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>6/50</strong>¿Cuál es la relación entre el
                      significado de las siguientes oraciones?
                    </h3>
                    <ol>
                      <li class="opc">
                        “Más sabe el diablo por viejo que por diablo”
                      </li>
                      <li class="opc">
                        “La experiencia es la madre de la ciencia”
                      </li>
                    </ol>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p6"
                              class="icheck"
                            />
                            Son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p6"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p6"
                              class="icheck"
                            />
                            No tienen relación
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>7/50</strong>Si es verdad que “El piano está
                      afinado con la guitarra” y también es verdad que “La
                      guitarra está afinada con la flauta” ¿Qué se puede decir
                      de la siguiente frase?:
                    </h3>
                    <h3> “La flauta está afinada con el piano”</h3>
                    <div class="row">
                      <div class="aux-p">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p7"
                              class="icheck"
                            />
                            Es correcta
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p7"
                              class="icheck"
                            />
                            Es falsa
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p7"
                              class="icheck"
                            />
                            No se puede saber
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>8/50</strong>En el siguiente grupo de palabras
                      ¿Cuál de ellas difiere de las otras?
                    </h3>
                    <div class="row">
                      <div class="aux-p2">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p8"
                              class="icheck"
                            />
                            Res
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p8"
                              class="icheck"
                            />
                            Atún
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p8"
                              class="icheck"
                            />
                            Ternera
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p8"
                              class="icheck"
                            />
                            Tocino
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p8"
                              class="icheck"
                            />
                            Salchicha
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>9/50</strong>¿Cuál es la relación entre los
                      significados de las siguientes oraciones?
                    </h3>
                    <ol>
                      <li class="opc">“Créate la fama y échate a dormir”</li>
                      <li class="opc">
                        “La suerte de la fea, la bonita la desea"
                      </li>
                    </ol>
                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p9"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p9"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p9"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>10/50</strong> La mayoría de las palabras
                      indicadas a continuación se parecen entre sí ¿Cuál de
                      entre ellas se parece menos?
                    </h3>
                    <div class="row">
                      <div class="aux-p5">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p10"
                              class="icheck"
                            />
                            Sospecha
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p10"
                              class="icheck"
                            />
                            Incredulidad
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p10"
                              class="icheck"
                            />
                            Duda
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p10"
                              class="icheck"
                            />
                            Determinación
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p10"
                              class="icheck"
                            />
                            Recelo
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>11/50</strong> Sostener es lo opuesto de:
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p11"
                              class="icheck"
                            />
                            Mantener
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p11"
                              class="icheck"
                            />
                            Sustentar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p11"
                              class="icheck"
                            />
                            Apreciar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p11"
                              class="icheck"
                            />
                            Desertar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p11"
                              class="icheck"
                            />
                            Apuntalar
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>12/50</strong>Suponga que las dos primeras
                      oraciones son ciertas ¿Qué se puede decir de la tercera?
                    </h3>
                    <ol>
                      <li class="opc">“Estos cachorros son perros normales</li>
                      <li class="opc">
                        “Todos los perros normales son activos”
                      </li>
                      <li class="opc">“Estos cachorros son activos”</li>
                    </ol>

                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p12"
                              class="icheck"
                            />
                            Es cierta
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p12"
                              class="icheck"
                            />
                            Es falsa
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p12"
                              class="icheck"
                            />
                            Es incierta
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question ">
                      <strong>13/50</strong> Entre los cinco números de las dos
                      columnas siguientes ¿Cuántos son exactamente iguales?
                    </h3>
                    <table>
                      <tr>
                        <td>7362</td>
                        <td>7363</td>
                      </tr>
                      <tr>
                        <td>62738</td>
                        <td>63738</td>
                      </tr>
                      <tr>
                        <td>527182</td>
                        <td>527182</td>
                      </tr>
                      <tr>
                        <td>918264</td>
                        <td>918264</td>
                      </tr>
                      <tr>
                        <td>1628357</td>
                        <td>1638357</td>
                      </tr>
                    </table>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                            type="number"
                            name="p13"
                            class="form-control espacio2"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>14/50</strong>El alambre se vende a 125 pesos el metro ¿Cuánto metros se puede comprar con un 1000 pesos?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group espacio">
                          <input
                            type="number"
                            name="p14"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>15/50</strong> Engaño es lo opuesto de:
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p15"
                              class="icheck"
                            />
                            Falsedad
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p15"
                              class="icheck"
                            />
                            Maña
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p15"
                              class="icheck"
                            />
                            Franqueza
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p15"
                              class="icheck"
                            />
                            Finura
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p15"
                              class="icheck"
                            />
                            Mentira
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>16/50</strong>Suponga que las dos primeras
                      oraciones son correctas ¿Qué se puede decir de la tercera?
                    </h3>
                    <ol>
                      <li class="opc">
                        “A todos los pelirrojos les gusta los dulces”
                      </li>
                      <li class="opc">“Carlos es pelirrojo”</li>
                      <li class="opc">“A Carlos le gustan los dulces”</li>
                    </ol>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p16"
                              class="icheck"
                            />
                            Es cierto
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p16"
                              class="icheck"
                            />
                            Es falsa
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p16"
                              class="icheck"
                            />
                            Es incierto
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>17/50</strong>Una compañía compró televisores por
                      $2.500. Los vendío a $2.900 y ganó $50 por cada televisor
                      ¿Cuántos televisores tenía?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group espacio">
                          <input
                            type="number"
                            name="p17"
                            class="form-control"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>18/50</strong>Absurdo-Acceder ¿Cuál es la relación
                      entre los significados de estas palabras?
                    </h3>
                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p18"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p18"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p18"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>19/50</strong> Dos de los siguientes proverbios
                      tienen sentidos similares ¿Cuáles son?
                    </h3>
                    <div class="row">
                      <div class="aux-p6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="checkbox"
                              value="1"
                              name="p19_1"
                              id="p19-1"
                              class="icheck"
                            />
                            "El que quiera celeste, que el cueste”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="2"
                              name="p19_2"
                              id="p19-2"
                              class="icheck"
                            />
                            “A palabras necias, oídos sordos”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="3"
                              name="p19_3"
                              id="p19-3"
                              class="icheck"
                            />
                            "El que quiera peces, tendrá que mojarse”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="4"
                              name="p19_4"
                              id="p19_4"
                              class="icheck"
                            />
                            “Dime con quién andas y te diré quién eres”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="5"
                              name="p19_5"
                              id="p19_5"
                              class="icheck"
                            />
                            “Todo bicho que camina va a parar al asador”
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>20/50</strong>En el siguiente grupo de palabras
                      ¿cuál difiere del resto de ellas?
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p20"
                              class="icheck"
                            />
                            Pequeño
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p20"
                              class="icheck"
                            />
                            Chico
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p20"
                              class="icheck"
                            />
                            Diminuto
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p20"
                              class="icheck"
                            />
                            Espacioso
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p20"
                              class="icheck"
                            />
                            Preciso
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>21/50</strong>Adornar es lo opuesto de:
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p21"
                              class="icheck"
                            />
                            Decorar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p21"
                              class="icheck"
                            />
                            Ornamentar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p21"
                              class="icheck"
                            />
                            Embellecer
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p21"
                              class="icheck"
                            />
                            Acicalar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p21"
                              class="icheck"
                            />
                            Desfigurar
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>22/50</strong>¿Cuál es la relación entre el
                      significado de las siguientes oraciones?
                    </h3>
                    <ol>
                      <li class="opc">“Los sueños, sueños son”</li>
                      <li class="opc">“Soñar no cuesta nada”</li>
                    </ol>
                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p22"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p22"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p22"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>23/50</strong> Dos de los siguientes proverbios
                      tienen un significado similar ¿Cuáles son?
                    </h3>
                    <div class="row">
                      <div class="aux-p6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="checkbox"
                              value="1"
                              name="p23-1"
                              id="p23-1"
                              class="icheck"
                            />
                            “Si la envidia fuera tiña. cuántos tiñosos habría”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="2"
                              name="p23-2"
                              id="p23-2"
                              class="icheck"
                            />
                            “En boca cerrada no entran moscas”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="3"
                              name="p23-3"
                              id="p23-3"
                              class="icheck"
                            />
                            "Haz bien sin mirar a quien”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="4"
                              name="p23-4"
                              id="p23-4"
                              class="icheck"
                            />
                            “Cría cuervos y te sacarán los ojos”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="5"
                              name="p23-5"
                              id="p23-5"
                              class="icheck"
                            />
                            “El que dice lo que quiere, oye lo que no quiere”
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>24/50</strong> Un depósito rectangular,
                      completamente lleno, almacena 640 metros cúbicos de grano.
                      Si el depósito mide 8 metros de ancho y 10 metros de largo
                      ¿Qué profundidad tiene?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                            type="number"
                            name="p24"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>25/50</strong>Ira es lo opuesto de:
                    </h3>
                    <div class="row">
                      <div class="aux-p8">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p25"
                              class="icheck"
                            />
                            Furia
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p25"
                              class="icheck"
                            />
                            Fastidio
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p25"
                              class="icheck"
                            />
                            Paciencia
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p25"
                              class="icheck"
                            />
                            Disgusto
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p25"
                              class="icheck"
                            />
                            Resentimiento
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>26/50</strong>Suponga que las dos primeras
                      oraciones son ciertas ¿Qué se puede decir de la tercera?
                    </h3>
                    <ol>
                      <li class="opc">“Estos niños son personas normales”</li>
                      <li class="opc">“Todos los niños normales comen bien”</li>
                      <li class="opc">“Estos niños comen bien”</li>
                    </ol>
                    <div class="row">
                      <div class="aux-p8">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p26"
                              class="icheck"
                            />
                            Es cierta
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p26"
                              class="icheck"
                            />
                            Es falsa
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p26"
                              class="icheck"
                            />
                            Es incierta
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>27/50</strong> Un niño tiene 10 años y su hermana
                      tiene el doble de su edad. Cuando el niño cumpla 16 años
                      ¿Qué edad tendrá su hermana?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                           
                            type="number"
                            name="p27"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>28/50</strong>¿Cuál es la relación entre los
                      significados de las siguientes oraciones?
                    </h3>
                    <ol>
                      <li class="opc">“Ver, oír y callar”</li>
                      <li class="opc">“Ver para creer”</li>
                    </ol>
                    <div class="row">
                      <div class="aux-p9">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p28"
                              class="icheck"
                            />
                            Son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p28"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p28"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>29/50</strong>En el siguiente grupo de palabras
                      ¿Cuál de ellas difiere de las otras?
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p29"
                              class="icheck"
                            />
                            Aroma
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p29"
                              class="icheck"
                            />
                            Fragancia
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p29"
                              class="icheck"
                            />
                            Agrio
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p29"
                              class="icheck"
                            />
                            Perfume
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p29"
                              class="icheck"
                            />
                            Olor
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>30/50</strong>Ocultarse-Ocuparse ¿Cuál es la
                      relación entre el significado de estas palabras?
                    </h3>

                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p30"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p30"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p30"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>31/50</strong> Cuatro de las siguientes figuras
                      pueden ensamblarse para formar un triángulo ¿Cuáles son?
                    </h3>
                    <div class="row">
                      <div class="aux-p6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="checkbox"
                              value="1"
                              name="p31-1"
                              id="p31-1"
                              class="icheck"
                            />
                            <img
                              className="rounded mx-auto d-block"
                              src="img/cascua-08.png"
                              width="100px"
                              class="img-form"
                            />
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="2"
                              name="p31-2"
                              id="p31-2"
                              class="icheck"
                            />
                            <img
                              className="rounded mx-auto d-block"
                              src="img/cuadrado-07.png"
                              width="100px"
                              class="img-form"
                            />
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="3"
                              name="p31-3"
                              id="p31-3"
                              class="icheck"
                            />
                            <img
                              className="rounded mx-auto d-block"
                              src="img/triangulo-09.png"
                              width="100px"
                              class="img-form"
                            />
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="4"
                              name="p31-4"
                              id="p31-4"
                              class="icheck"
                            />
                            <img
                              className="rounded mx-auto d-block"
                              src="img/cuadradito-011.png"
                              width="60px"
                              class="img-form"
                            />
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="5"
                              name="p31-5"
                              id="p31-5"
                              class="icheck"
                            />
                            <img
                              className="rounded mx-auto d-block"
                              src="img/triangulo-010.png"
                              width="100px"
                              class="img-form"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>32/50</strong>Obtener-Recuperar ¿Cuál es la
                      relación entre el significado de estas palabras?
                    </h3>

                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p32"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p32"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p32"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>33/50</strong> Del siguiente grupo de números
                      ¿Cuál representa el valor más pequeño?
                    </h3>
                    <div class="row">
                      <div class="aux-p7">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p33"
                              class="icheck"
                            />
                            0.999
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p33"
                              class="icheck"
                            />
                            999
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p33"
                              class="icheck"
                            />
                            0.9
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p33"
                              class="icheck"
                            />
                            1
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p33"
                              class="icheck"
                            />
                            2
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="6"
                              name="p33"
                              class="icheck"
                            />
                            0.88
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>34/50</strong>¿Cuál es la relación entre el
                      significado de las siguientes oraciones?
                    </h3>
                    <ol>
                      <li class="opc">“A falta de pan, buenas son tortas”</li>
                      <li class="opc">“A buen hambre, no hay pan duro”.</li>
                    </ol>
                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p34"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p34"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p34"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>35/50</strong> El precio de la harina aumentó de
                      16.4 centavos a 20.5 centavos ¿En qué porcentaje aumentó
                      el costo de la harina?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                            type="number"
                            name="p35"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>36/50</strong> Apelar es lo opuesto de:
                    </h3>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p36"
                              class="icheck"
                            />
                            Suplicar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p36"
                              class="icheck"
                            />
                            Implorar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p36"
                              class="icheck"
                            />
                            Pedir
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p36"
                              class="icheck"
                            />
                            Negar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p36"
                              class="icheck"
                            />
                            Invocar
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>37/50</strong> Dos de los siguientes proverbios
                      tienen un significado similar ¿Cuáles son?
                    </h3>
                    <div class="row">
                      <div class="aux-p6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="checkbox"
                              value="1"
                              name="p37-1"
                              id="p37-1"
                              class="icheck"
                            />
                            “Al camarón que se duerme, se lo lleva la corriente”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="2"
                              name="p37-2"
                              id="p37-2"
                              class="icheck"
                            />
                            “Tanto va el cántaro a la fuente, que al final se
                            rompe”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="3"
                              name="p37-3"
                              id="p37-3"
                              class="icheck"
                            />
                            “No es padre quien engendra, sino quien cría”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="4"
                              name="p37-4"
                              id="p37-4"
                              class="icheck"
                            />
                            “Al amigo y al caballo no hay que cansarlo
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="5"
                              name="p37-5"
                              id="p37-5"
                              class="icheck"
                            />
                            “A Dios rogando y con el mazo dando”
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>38/50</strong> Ordene las siguientes palabras para
                      que formen una oración.</h3>
                    <h4>Cuando-Llueve-está-nublado</h4>
                    <h4>
                       ¿La oración es correcta?
                    </h4>
                    
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p38"
                              class="icheck"
                            />
                            Verdadero
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p38"
                              class="icheck"
                            />
                            Falso
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>39/50</strong> Un reloj daba la hora exacta al
                      mediodía del lunes. A las 8 de la noche del día martes, se
                      había atrasado 64 segundos. A ese ritmo ¿Cuánto se atrasó
                      en media hora?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                          
                            type="number"
                            name="p39"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>40/50</strong> Rechazar es lo opuesto de:
                    </h3>
                    <div class="row">
                      <div class="aux-p8">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p40"
                              id="p40"
                              class="icheck"
                            />
                            Rehusar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p40"
                              id="p40"
                              class="icheck"
                            />
                            Expulsar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p40"
                              id="p40"
                              class="icheck"
                            />
                            Despedir
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p40"
                              id="p40"
                              class="icheck"
                            />
                            Alejar
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p40"
                              id="p40"
                              class="icheck"
                            />
                            Estimar
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>41/50</strong> Si 3 ½ Costales de semilla cuestan
                      $35 ¿Cuánto costarán 4 ½ costales?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                           
                            type="number"
                            name="p41"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>42/50</strong>¿Cuál es la relación entre el
                      significado de las siguientes oraciones?
                    </h3>
                    <ol>
                      <li class="opc">
                        “Si tienes muchos hierros en la fragua, algunos se
                        enfriaran”
                      </li>
                      <li class="opc">
                        “El que a hierro mata, a hierro muere”
                      </li>
                    </ol>
                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p42"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p42"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p42"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>43/50</strong> En el siguiente grupo de números
                      ¿Cuál de ellos representa el valor más pequeño?
                    </h3>
                    <div class="row">
                      <div class="aux-p7">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p4"
                              id="p43"
                              class="icheck"
                            />
                            222
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p43"
                              id="p43"
                              class="icheck"
                            />
                            0.9
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p43"
                              id="p43"
                              class="icheck"
                            />
                            0.73
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p43"
                              id="p43"
                              class="icheck"
                            />
                            2
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p43"
                              id="p43"
                              class="icheck"
                            />
                            4
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="6"
                              name="p43"
                              id="p43"
                              class="icheck"
                            />
                            0.49
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question sinMargen">
                      <strong>44/50</strong> La siguiente figura geométrica
                      puede cortarse con una línea recta y dividirse en dos
                      partes que pueden ensamblarse para formar un cuadrado
                      perfecto. Dibuje imaginariamente esa línea uniendo dos
                      números ¿cuáles son?
                    </h3>
                    <img
                      className="rounded mx-auto d-block"
                      src="img/img-012.png"
                      width="310px"
                    />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                           
                            type="text"
                            name="p44"
                            class="form-control"
                            placeholder="Respuesta Ejemplo  1,2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>45/50</strong> Tres personas forman una sociedad y
                      acuerdan dividir las ganancias en partes iguales. X
                      invierte $6.500, Y invierte $2.000 y Z invierte $1.500. Si
                      las ganancias son de $3.000 ¿Cuánto menos recibe X que si
                      las ganancias se dividieran en proporción a las cantidades
                      invertidas?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                            
                            type="number"
                            name="p45"
                            class="form-control espacio3"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>46/50</strong> ¿Cuál es el siguiente número de
                      esta serie? 16-4-1-.25-?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            
                            type="number"
                            name="p46"
                            class="form-control espacio"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>47/50</strong> Dos de los siguientes proverbios
                      tienen sentido similar ¿Cuáles son?
                    </h3>
                    <div class="row">
                      <div class="aux-p6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="checkbox"
                              value="1"
                              name="p47-1"
                              id="p47-1"
                              class="icheck"
                            />
                            “Con plata nada falta”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="2"
                              name="p47-2"
                              id="p47-2"
                              class="icheck"
                            />
                            “Los niños y los locos dicen la verdad”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="3"
                              name="p47-3"
                              id="p47-3"
                              class="icheck"
                            />
                            “No hay borracho que pierda el juicio, ni loco el
                            entendimiento”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="4"
                              name="p47-4"
                              id="p47-4"
                              class="icheck"
                            />
                            “A barriga llena, corazón contento”
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              value="5"
                              name="p47-5"
                              id="p47-5"
                              class="icheck"
                            />
                            “No hay feo sin su gracia, ni bonita sin su defecto”
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>48/50</strong>Rescate y recompensa ¿Cuál es la
                      relación entre los significados de estas dos palabras?
                    </h3>
                    <div class="row">
                      <div class="aux-p3">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p48"
                              class="icheck"
                            />
                            Los significados son similares
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p48"
                              class="icheck"
                            />
                            Son opuestos
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p48"
                              class="icheck"
                            />
                            No son ni similares ni opuestos
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <h3 class="main_question">
                      <strong>49/50</strong> Al imprimir un artículo de 24.000
                      palabras, un impresor decide emplear dos tamaños de letra.
                      Usando el más grande, una página impresa contiene 1200
                      palabras. Utilizando el más pequeño, la página contiene
                      1.500 palabras. El artículo debe ocupar 17 páginas
                      completas de la revista ¿Cuántas páginas deben imprimirse
                      usando letras pequeñas?
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-groups">
                          <input
                          
                            type="number"
                            name="p49"
                            class="form-control espacio2"
                            placeholder="Respuesta"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="step submit">
                    <h3 class="main_question ">
                      <strong>50/50</strong> En la siguiente serie de números,
                      uno de ellos no cuadra con la pauta establecida por los
                      otros ¿Cuál sería ese número?
                    </h3>
                    <h3>1/1000 - 1/100 - 1/10 - 0 - 1/10 - 10</h3>
                    <div class="row">
                      <div class="aux-p6">
                        <div class="form-group radio_input res">
                          <label>
                            <input
                              type="radio"
                              value="1"
                              name="p50"
                              id="p50"
                              class="icheck"
                            />
                            1/1000
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="2"
                              name="p50"
                              id="p50"
                              class="icheck"
                            />
                            1/100
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="3"
                              name="p50"
                              id="p50"
                              class="icheck"
                            />
                            1/10
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="4"
                              name="p50"
                              id="p50"
                              class="icheck"
                            />
                            0
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="5"
                              name="p50"
                              id="p50"
                              class="icheck"
                            />
                            1/10
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="6"
                              name="p50"
                              id="p50"
                              class="icheck"
                            />
                            10
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="bottom-wizard">
                  <button
                    id="ante"
                    type="button"
                    name="backward"
                    class="backward"
                    onClick={this.backward}
                  >
                    Anterior
                  </button>
                  <button
                    id="sig"
                    type="button"
                    name="forward"
                    class="forward"
                    onClick={this.forward}
                  >
                    Siguiente
                  </button>
                  <button type="submit" name="process" class="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Preguntas;
