import React from "react";
import axios from "axios";

class Final extends React.Component {

  render() {
    return (
      <div id="form_container">
        <div class="row">
          <div class="col-lg-12">
            <div id="wizard_container" class="final">
                Gracias por tus respuestas, dependiendo de tu desempeño te estaremos notificando de los siguientes pasos!!
                <img
                        className="rounded mx-auto d-block"
                        src="img/img-011.png"
                        width="280px"
                        class="finalImg"
                      />  
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Final;
